import { Card, Row, Table } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import OPSpinner from "../../components/others/OPSpinner";
import auth from "../../helpers/auth";
import parse from "html-react-parser";
import moment from "moment";
import { setMessageValue } from "../../helpers/messageCounter";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import MetadataSetup from "../../components/MetadataSetup";
import Log from "../../components/analytics/Log";

import messageHelper from "../../helpers/messageHelper";
import axiosConfig from "../../helpers/axiosConfig";
import axiosWithCacheConfig from "../../helpers/axiosWithCacheConfig"


// import filterList from "../../assets/images/others/filter-list.svg";

function MainMessages() {
  // const searchRef = useRef();
  const [loading, SetLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const [loadMore, setLoadMore] = useState(false);
  const [itemLoader, SetItemLoader] = useState(false);
  const [messages, SetMessages] = useState([]);
  // eslint-disable-next-line
  const [origMessages, SetOrigMessages] = useState([]);
  const dispatch = useDispatch();
  const MessageContainer = useRef();
  // eslint-disable-next-line
  const [filtering, setFiltering] = useState(false);
  let isLastRow = false;
  const getmessageCount = () => {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3002/op/v1/messages/count":"https://api.onepeople.online/op/v1/messages/count";
    axiosConfig
      .get(url, {
        params: { action: "refresh" },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((e) => {
        dispatch(setMessageValue(e.data.count));
      });
  };
  let itNum = 1;
  const loadMessages = () => {
    let savedMessage = messageHelper.getUserMessage();

    if (savedMessage.length !== 0) {
      setPage(page + 1);
      SetLoading(false);
      setLoadMore(true);
      SetMessages(savedMessage);
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3002/op/v1/messages":"https://api.onepeople.online/op/v1/messages";
    axiosWithCacheConfig
      .get(url, {
        params: { page: itNum },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((e) => {
        itNum = itNum + 1;
        SetLoading(false);
        setLoadMore(true);
        e.data.map((f) => {
          if (f.hasNext === false) {
            setLoadMore(false);
          }
          return f;
        });
        SetMessages(e.data);
        SetOrigMessages(e.data);
        getmessageCount();
      })
      .catch((e) => {
        auth.checkErrors(e);
        SetLoading(false);
        setError(true);
      });
  };
  const LoaderElement = () => {
    return (
      <tr>
        <td colSpan={2} className="text-center FS12 justify-content-center ">
          <span className="mx-auto d-inline-block">
            <OPSpinner width={30} size={"xtrasmall"} />
          </span>
        </td>
      </tr>
    );
  };
  let isLoading = false;
  const load_more = (e) => {
    if (isLoading) {
      return;
    }
    isLoading = true;
    SetItemLoader(true);
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3002/op/v1/messages":"https://api.onepeople.online/op/v1/messages";

    axiosWithCacheConfig
      .get(url, {
        params: { page: itNum },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((e) => {
        itNum = itNum + 1;
        SetItemLoader(false);
        isLoading = false;
        let data = e.data;
        data.map((e) => {
          if (e.hasNext === false) {
            setLoadMore(false);
            isLastRow = true;
          }
          return e;
        });

        SetMessages((old_message) => [...old_message, ...data]);
        SetOrigMessages(data);
        getmessageCount();
      })
      .catch((e) => {
        auth.checkErrors(e);
        SetLoading(false);
        setError(true);
      });
  };
  // function onSearch(e) {
  //   if (e.target.value === "") {
  //     SetMessages(origMessages);
  //     setFiltering(false);
  //     return;
  //   } else {
  //     setFiltering(true);
  //   }
  //   SetMessages(
  //     messages.filter((d) => {
  //       // console.log(d.Messages);
  //       if (d.Messages !== undefined) {
  //         return d.Messages.includes(e.target.value) > 0;
  //       }

  //       return d;
  //     })
  //   );
  // }
  const MessageList = (props) => {
    let tmp = [];

    messages.map((e) => {
      if (e.hasNext !== undefined || e.hasNext === false) {
        // setLoadMore(false)
      } else {
        tmp.push(
          <tr key={e.id}>
            <td>
              {e.status === 1
                ? "new"
                : moment(e.created_at).format("D MMM YYYY h:mma")}
            </td>
            <td>{parse(e.Messages)}</td>
          </tr>
        );
      }

      return e;
    });

    return tmp;
  };
  const Loader = () => {
    return (
      <>
        <div className="flex-fill d-flex flex-column justify-content-center">
          <div
            className="text-center d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "12.5rem" }}
          >
            <OPSpinner width={30} />
            <div>...</div>
          </div>
        </div>
      </>
    );
  };

  const handleScroll = async () => {
    let lastElTop =
      MessageContainer.current.lastChild.offsetTop +
      MessageContainer.current.lastChild.clientHeight -
      MessageContainer.current.lastChild.offsetTop;
    let scrollTop =
      MessageContainer.current.scrollTop +
      MessageContainer.current.clientHeight;
    if (scrollTop >= lastElTop) {
      if (!isLastRow) {
        await load_more();
      }
    }
  };
  useEffect(() => {
    loadMessages();
    MessageContainer.current.addEventListener("scroll", handleScroll);
  }, 
  // eslint-disable-next-line
  []);

  // function onSubmit(event) {
  //   event.preventDefault();
  // }
  return (
    <Log logImpression event="page-view" page="Messages">
      <MetadataSetup 
        title="Messages | OnePeople" 
        canonical="https://onepeople.online/messages/"
        description="Messages Page of OnePeople Online"
      />
      {/* <Container> */}
      <Row className="justify-content-center">
        <motion.div
          className="col-12 col-md-12 col-lg-10 col-xl-9 mt-1"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <Card className="op-box OPcard-rounded card-transparent pe-1">
            <Card.Header className="clear-header pt-4 pb-2 fblack FS18 d-flex px-4">
              <h4 className="gp-header-text">📬 Messages</h4>
              <div className="flex-fill justify-content-end d-flex">
                <div>
                  {/* <form className="search-form" onSubmit={onSubmit}>
                    <div>
                      <img
                        src={filterList}
                        alt="search icon"
                        className="icon icon-xs"
                        style={{ top: "50%", transform: "translateY(-50%)" }}
                      />
                      <input
                        type="text"
                        className="search-input-xs history-search-input"
                        placeholder="Keyword filter"
                        ref={searchRef}
                        style={{ height: "2.0625rem" }}
                        onChange={onSearch}
                      />
                    </div>
                  </form> */}
                </div>
              </div>
            </Card.Header>
            <Card.Body className="msgs-container mz-scroll" ref={MessageContainer}>
              {loading ? (
                <Loader />
              ) : (
                <Table className="OPTable mb-0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {error ? (
                      <tr>
                        <td colSpan={2} className="text-center FS12 text-gray py-5">
                          Oops! something went wrong!
                        </td>
                      </tr>
                    ) : messages.length > 1 ? (
                      <>
                        <MessageList />
                        {itemLoader ? <LoaderElement /> : ""}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={2} className="text-center FS12 text-gray py-5" style={{fontSize: "1.15rem"}}>
                          No Messages Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </motion.div>
      </Row>
      {/* </Container> */}
    </Log>
  );
}

export default MainMessages;
