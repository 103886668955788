import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import auth from "./helpers/auth";
import store from "./store"
import { Provider } from 'react-redux'
//Components
import Root from "./Root.js";
import axiosConfig from "./helpers/axiosConfig";
import axiosWithCacheConfig from "./helpers/axiosWithCacheConfig";
// Pages
import NonUserDetails from "./pages/profile/nonuser/NonUserDetails";
import UserDetails from "./pages/profile/user/UserDetails";
import MyHistory from "./pages/history/MyHistory";
import Search from "./pages/referendums/Search";
import Explore from "./pages/explore/Explore";
import ReferendumPage from "./pages/referendums/Referendum";
import CreateReferendum from "./pages/referendums/CreateReferendum";
import LeaderBoard from "./pages/leaderboard/LeaderBoard";
import MainMessages from "./pages/messages/MainMessages";
import AboutPage from "./pages/about/AboutPage";
import ContactUsPage from "./pages/contactus/ContactUsPage";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import EmailVerify from "./pages/profile/EmailVerify";
import Follows from "./pages/my_followed_referendums/Followed";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import LegalNotice from "./pages/legal/LegalNotice";
import ErrorPage from "./pages/errors/ErrorPage";

let router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/referendums/:slug/",
        loader: async ({ params }) => {

          let maintenance_mode = process.env.REACT_APP_MAINTENANCE == 1 ? true : false;
          if (maintenance_mode) {
            window.location.href = "/"
          } else {
            let headers;
            let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${params.slug}` : `https://api.onepeople.online/op/v1/referendums/${params.slug}`;
            if (auth.isOnline()) {
              headers = {
                Authorization: `Bearer ${auth.getToken()}`,
              };

              return axiosWithCacheConfig
                .get(
                  url,
                  {
                    headers,
                  }
                )
                .then((res) => res.data.referendum)
                .catch((e) => {
                  if (e.response.status === 404) {
                    throw new Response(e.response.data.errors[0].msg, { status: 404 });
                  } else {

                    auth.checkErrors(e);
                  }
                });


            } else {
              auth.logout();
              window.location.href = "/?redirect=" + window.location.href;
            }

          }
        },
        element: <ReferendumPage />,
      },
      {
        path: "/profile-viewer/",
        element: <NonUserDetails />,
      },
      {
        path: "/profile/",
        element: <UserDetails />,
      },
      {
        path: "/my-history/",
        element: <MyHistory />,
      },
      {
        path: "/my-follows/",
        element: <Follows />,
      },
      {
        path: "/search/",
        element: <Search />,
      },
      {
        path: "/top/",
        element: <Search />,
      },
      {
        path: "/hotly-contested/",
        element: <Search />,
      },
      {
        path: "/trending/",
        element: <Search />,
      },
      {
        path: "/help-them-out/",
        element: <Search />,
      },
      {
        path: "/hot-follows/",
        element: <Search />,
      },
      {
        path: "/explore/",
        element: <Explore />,
        children: [
          {
            path: '/explore/:category/',
            element: <Explore />,
          },
          {
            path: '/explore/:category/:country/',
            element: <Explore />,
          },
        ]
      },
      {
        path: "/create/",
        element: <CreateReferendum />,
      },
      {
        path: "/leaderboards/",
        element: <LeaderBoard />,
      },
      {
        path: "/messages/",
        element: <MainMessages />,
      },
      {
        path: "/about-us/",
        element: <AboutPage />,
      },
      {
        path: "/contact-us/",
        element: <ContactUsPage />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: '/email-verify/:token',
        element: <EmailVerify />,
        loader: async ({ request, params }) => {

          return params.token;
        }
      },
      {
        path: "/privacy-policy/",
        element: <PrivacyPolicy />
      },
      {
        path: "/legal-notice/",
        element: <LegalNotice />
      }
    ],
  },
]);
if (window.self === window.top) {
  var antiClickjack = document.getElementById("antiClickjack");
  antiClickjack.parentNode.removeChild(antiClickjack);
} else {
  window.top.location = window.self.location;
}


ReactDOM.createRoot(document.getElementById("ed-root")).render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
  //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
